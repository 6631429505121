import './App.css';
import Lobby from './components/lobby/lobby';

function App() {
  return (
    <div className="App">
      <Lobby></Lobby>
      
     
    </div>
  );
}

export default App;
